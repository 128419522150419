@iconColorDefault: #FF8946;
@iconColorActive: #000000;
@blue: #01628A;

body, html {
  height: 100%!important;
  font-family: Roboto, Ubuntu, Arial, serif;
  margin: 0;
  padding: 0;
  background-color: #DBDBDB;
  color: #3D3D3D;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder{
  opacity: .1;
}

.cdk-drag-animating .issue-item{
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.issue-item:last-child {
  border: none;
}

.coll.cdk-drop-list-dragging .issue-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

app-analytics {
  overflow-y: scroll;
}

.mat-form-field {
  display: block!important;
}

.mat-form-field .mat-form-field-infix {
  width: auto;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0!important;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: #d2d1d1!important;
  -webkit-text-fill-color: #d2d1d1!important;
  font-size: 14px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0!important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0!important;
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: 0 solid transparent!important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: transparent!important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background: none!important;
}

mat-form-field {
  margin: 50px 0;
  width: 100%;
}

.mat-datepicker-input-wrapper {

  .mat-form-field-suffix {
    bottom: 7px;
    border: 1px solid #C4C4C4;
    border-left: none;
    line-height: 30px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.mat-select-panel-custom {
  margin-top: 30px;
}

.mat-form-field {

  input {
    line-height: 30px;
    padding-left: 5px;
    font-size: 14px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-right: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
}

.mat-select {
  line-height: 30px;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 4px;

  .mat-select-arrow {
    background: url(/assets/arrow-down.svg);
    background-repeat: no-repeat;
    border: 0;
    width: 18px;
    height: 100%;
    margin: 0 7px;
    background-position-x: 50%;
    background-position-y: 50%;
  }
}

.mat-form-field-label-wrapper {
  padding-top: 25px!important;
  top: -25px !important;

  label {
    position: absolute;
    left: 0!important;
    top: 0!important;
    font-weight: 500;
    font-size: 14px;
    color: #3D3D3D;
  }
}

.mat-form-field-infix {

  label.label-custom {
    position: absolute;
    top: -25px;
    font-weight: 500;
    font-size: 14px;
  }
}

.cb {clear: both}

/* You can add global styles to this file, and also import other style files */
